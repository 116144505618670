// This class name will be applied on document.documentElement if user has installed extension
export const EXTENSION_IS_INSTALLED_CLASSNAME =
  'js-storylane-extension' as const;

// This key will be placed in window.localStorage if InstallExtensionPopup.tsx has been mounted
export const AWAITING_EXTENSION_INSTALLED_KEY =
  'awaiting-extension-installed' as const;

export const IS_ONBOARDING_GUIDE_VISIBLE_KEY =
  'onboarding-guide-visible' as const;

export const EXTENSION_URL =
  'https://chromewebstore.google.com/detail/storylane/ongmhighpnfpojfidgigcjflgdkjfdeb';

export const BILLING_URL =
  'https://billing.stripe.com/p/login/7sIg1c9pm8bBgQ8cMM';

export const PRODUCT_DOCS_URL =
  'https://docs.storylane.io/storylane-knowledge-base/';

export const DEFAULT_PAGE_SIZE = 40;

export const hexColorReg = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/i;
export const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/i;
/** https://stackoverflow.com/a/7930801 */
export const subdomainRegex = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;

export enum UiActionType {
  DeleteFlow,
  ConvertWidget,
  DeleteWidget,
  DeletePage,
  DeleteFlowList,
  DesignRevealHiddenElements,
  DeleteIntegration,
  DeleteImageToken,
  DeleteTeamMember,
  DeleteProjectLink,
  DeleteWidgetAudio,
  DeleteWidgetVideo,
  DisableClearbit,
  DisableIpTracking,
  RevertOriginalImageModal,
  DeleteConvertCta,
  ConfirmFlow,
}

export const LANDING_URL = 'https://www.storylane.io';

export const DEFAULT_FONT_SIZE = 16;

/**
 * HTML element id where all React portals should be placed
 *
 */
export const PortalSlotId = 'portals-slot';

/**
 * TODO: figure out way to store all possible search params
 */
export enum SearchKeys {
  From = 'from',
}
/**
 * TODO: figure out way to store all possible search params
 */
export enum SearchValues {
  CreateStoryButton = 'createStoryButton',
}

export const ScreenBreakpointMobile = 600;
export const DemoPlayerSmScreemBottomSpace = 35; // @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/83001365/DRAFT+Responsive#Global-styles

export const embedIframeName = 'sl-embed' as const;

export const idAttrName = 'data-storylane-script-id';
