import cx from 'classnames';
import {
  useState,
  useEffect,
  Children,
  cloneElement,
  ReactElement,
  CSSProperties,
  FC,
  MouseEvent as ReactMouseEvent,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip, Config } from 'react-popper-tooltip';

import { PortalSlotId } from '../../features/ui/ui.constants';
import styles from './Tooltip.module.css';

export interface TooltipProps {
  isVisible?: boolean;
  useInternalVisible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  triggerOn?: 'click' | 'hover';
  defaultVisible?: boolean;
  closeOnInnerClick?: boolean;
  closeOnWheel?: boolean;
  preventDefaultOutsideClick?: boolean;
  hasArrow?: boolean;
  offsetX?: number;
  offsetY?: number;
  disabled?: boolean;
  /**
   * If `true`, hovering the tooltip will keep it open. Normally tooltip closes when the mouse cursor moves out of
   * the trigger element. If it moves to the tooltip element, the tooltip stays open.
   */
  isInteractive?: boolean;
  placement?: Config['placement'];
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  className?: string;
  closeOnOutsideClick?: Config['closeOnOutsideClick'];
  delayHide?: number | undefined;
  followCursor?: boolean;
  isStopPropagationAllowed?: boolean;
}

const Tooltip: FC<TooltipProps> & {
  Trigger: typeof TooltipTrigger;
  Content: typeof TooltipContent;
} = ({
  children,
  triggerOn = 'click',
  defaultVisible = false,
  isVisible,
  useInternalVisible,
  onVisibleChange,
  closeOnInnerClick = true,
  closeOnWheel,
  preventDefaultOutsideClick = false,
  closeOnOutsideClick = true,
  // hasArrow = false,
  offsetX = 0,
  offsetY = 0,
  disabled = false,
  placement,
  isInteractive,
  className,
  delayHide,
  followCursor,
  isStopPropagationAllowed = false,
}) => {
  const [isInternalVisible, setIsInternalVisible] = useState(defaultVisible);
  const isHoverType = triggerOn === 'hover';
  const isClickType = triggerOn === 'click';
  const hasArrow = false; // TODO: fix it properly next release

  const visibilityFlag = isVisible ?? isInternalVisible;
  const visibleIn =
    isClickType || useInternalVisible ? visibilityFlag : undefined;

  const x = offsetX ? offsetX : 0;
  let y = 2;
  if (offsetY) {
    y = offsetY;
  } else if (hasArrow) {
    y = 15;
  }

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    triggerRef,
    tooltipRef,
    visible,
  } = usePopperTooltip({
    visible: disabled ? false : visibleIn,
    trigger: !disabled ? triggerOn : null,
    interactive: isInteractive ?? isHoverType,
    defaultVisible,
    onVisibleChange: onVisibleChange || setIsInternalVisible,
    offset: [x, y],
    placement,
    closeOnOutsideClick,
    delayHide,
    followCursor,
  });

  useEffect(() => {
    if (preventDefaultOutsideClick && isVisible && onVisibleChange) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const listener = (event: any) => {
        const { target } = event;
        if (
          triggerRef &&
          !triggerRef.contains(target) &&
          tooltipRef &&
          !tooltipRef.contains(target)
        ) {
          event.stopPropagation();
          onVisibleChange(false);
          return;
        }
      };

      document.addEventListener('mousedown', listener, true);
      document.addEventListener('touchstart', listener, true);
      return () => {
        document.removeEventListener('mousedown', listener, true);
        document.removeEventListener('touchstart', listener, true);
      };
    }
  }, [
    onVisibleChange,
    isVisible,
    preventDefaultOutsideClick,
    tooltipRef,
    triggerRef,
  ]);

  const handleInnerClick = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isStopPropagationAllowed) {
      e.stopPropagation();
    }
  };

  const tryClose = useCallback(() => {
    if (onVisibleChange) {
      onVisibleChange(false);
    } else {
      setIsInternalVisible(false);
    }
  }, [onVisibleChange, setIsInternalVisible]);

  const handleWheel = useCallback(() => {
    tryClose();
  }, [tryClose]);

  const closeTooltip = () => {
    if (closeOnInnerClick) {
      tryClose();
    }
  };

  return (
    <div
      className={className}
      onClick={handleInnerClick}
      onWheel={closeOnWheel ? handleWheel : undefined}
    >
      {Children.map(children, (child) => {
        return cloneElement(child as ReactElement, {
          isContentVisible: visible,
          setTriggerRef,
          setTooltipRef,
          getTooltipProps,
          getArrowProps,
          closeTooltip,
          isVisible: visibilityFlag,
          hasArrow,
        });
      })}
    </div>
  );
};

export interface TooltipTriggerProps {
  onTriggerClick?: () => void;
  setTriggerRef?: (ref: HTMLDivElement | null) => void;
  isVisible?: boolean;
  onSetRef?: (ref: HTMLDivElement | null) => void;
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  className?: string;
  style?: CSSProperties;
}

const TooltipTrigger: FC<TooltipTriggerProps> = ({
  children,
  className,
  onTriggerClick,
  setTriggerRef,
  isVisible,
  onSetRef,
  style,
}) => {
  return (
    <div
      className={className}
      style={style}
      ref={(node) => {
        if (setTriggerRef) {
          setTriggerRef(node);
        }
        if (onSetRef) {
          onSetRef(node);
        }
      }}
      onClick={onTriggerClick}
    >
      {typeof children === 'function' ? children({ isVisible }) : children}
    </div>
  );
};
Tooltip.Trigger = TooltipTrigger;

export interface TooltipContentProps {
  variant?: 'light' | 'dark';
  isContentVisible?: boolean;
  closeTooltip?: () => void;
  getTooltipProps?: ReturnType<typeof usePopperTooltip>['getTooltipProps'];
  getArrowProps?: ReturnType<typeof usePopperTooltip>['getArrowProps'];
  hasArrow?: boolean;
  setTooltipRef?: (ref: HTMLDivElement | null) => void;
  onSetRef?: (ref: HTMLDivElement | null) => void;
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  className?: string;
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  innerClassName?: string;
  innerStyle?: CSSProperties;
}

const TooltipContent: FC<TooltipContentProps> = ({
  variant = 'light',
  children,
  isContentVisible,
  closeTooltip,
  getTooltipProps,
  setTooltipRef,
  onSetRef,
  className,
  innerClassName,
  getArrowProps = () => ({}),
  hasArrow,
  innerStyle,
}) => {
  if (!isContentVisible) return null;

  return createPortal(
    <div
      ref={(node) => {
        if (setTooltipRef) {
          setTooltipRef(node);
        }
        if (onSetRef) {
          onSetRef(node);
        }
      }}
      onPointerDown={(e) => e.stopPropagation()} // TODO: https://storylane.atlassian.net/browse/STORY-2562
      {...(getTooltipProps &&
        getTooltipProps({
          className,
          onClick: closeTooltip,
        }))}
    >
      {hasArrow && <div {...getArrowProps()} />}
      <div
        className={cx(
          styles.content,
          {
            [styles.contentLight]: variant === 'light',
          },
          innerClassName
        )}
        style={innerStyle}
      >
        {children}
      </div>
    </div>,
    document.getElementById(PortalSlotId) || document.documentElement
  );
};
Tooltip.Content = TooltipContent;

export default Tooltip;
