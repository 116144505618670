import { FC } from 'react';
import FullScreenAlert from 'shared/src/components/FullScreenAlert';
import Icon from 'shared/src/uikit/Icon';

type DemoNotFoundProps = {
  subject?: 'demo' | 'link';
  testId?: string;
};

const DemoNotFound: FC<DemoNotFoundProps> = ({ subject = 'demo', testId }) => {
  return (
    <FullScreenAlert
      testId={testId}
      icon={<Icon width={72.978} height={72.977} name="crossed-chain" />}
      primaryText="Whoops!"
      secondaryText={
        <>
          It looks like <b>your {subject}</b> is <b>not active.</b>
        </>
      }
    />
  );
};

export default DemoNotFound;
