/* eslint-disable @next/next/no-page-custom-font */
import Head from 'next/head';
import { VFC } from 'react';
import { GoogleFont } from 'shared/src/types/googlefonts.types';

const HeadCustomFont: VFC<{
  font: GoogleFont | null | undefined;
}> = ({ font }) => {
  if (font) {
    return (
      <Head>
        <link key="custom-font-link" href={font.url} rel="stylesheet" />
        <style
          key="custom-font-style"
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `
      :root {
        --project-font-family: ${font.fontFamily};
      }
    `,
          }}
        />
      </Head>
    );
  }

  // Poppins is default for the application
  return (
    <Head>
      <link
        key="custom-font-link"
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
        rel="stylesheet"
      />
    </Head>
  );
};

export default HeadCustomFont;
